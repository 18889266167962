body {
  scale: 1;
  margin: 0;
  background-image: linear-gradient(
    to right bottom,
    #0e4981,
    #2c5c91,
    #436fa1,
    #5a84b1,
    #7098c1,
    #73a9ca,
    #7cbad1,
    #8bcad7,
    #9bd7d0,
    #b7e2c8,
    #d8eac5,
    #faf0ca
  );
  /* Removing arrow from input */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  /* Removing arrow from input Done */

  /* background-image: linear-gradient(
    to right bottom,
    #845ec2,
    #a55dbd,
    #c15db5,
    #d95fab,
    #ec64a0,
    #f66b93,
    #fc7587,
    #ff807d,
    #ff8f72,
    #ffa168,
    #ffb461,
    #ffc75f
  ); */

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
